body {
  font-family: "Open Sans", sans-serif;
  background: #fff;
}
app-pages-login main{
  background:#fff;
  background-image: url("/assets/img/background.png");    
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.pagetitle h1 {
  color: #444;
}
.pagetitle h1::first-letter {
  color: #2a95c3;
}

.titulito{color: #2a95c3;}
.btn-primary {
  color: #ffffff !important;
  background-color:#2a95c3 !important;
  border-color: #2a95c3 !important;
}
.btn-primary:hover{
  color:#ffffff !important;
  background-color:#12526d !important;
  border-color:#2a95c3 !important;
}
.sidebar-nav .nav-link:hover, .sidebar-nav .nav-link.collapsed i:hover, .sidebar-nav .nav-link i:hover, .sidebar-nav .nav-link a:hover,
.sidebar-nav .nav-link:hover, .sidebar-nav .nav-content a:hover, .sidebar-nav .nav-item:hover , .sidebar-nav .nav-link.collapsed:hover{
  color: #2a95c3 !important;   
    /*background-color: rgba(0, 0, 0, 0.05);*/
}

.sidebar-nav .nav-content a , 
.sidebar-nav .nav-link.collapsed i , 
.sidebar-nav .nav-link ,
.sidebar-nav .nav-link i,
.sidebar-nav .nav-link.collapsed{
  color:  #444 !important;
  background:transparent;
  font-size: 1.2em;
  font-weight: normal;
}

.sidebar-nav .nav-link.collapsed i:hover , 
.sidebar-nav .nav-link i:hover{
  color:  #2a95c3 !important;
  background:transparent;
  font-size: 1.2em;
  font-weight: normal;
}

.sidebar {
background-color: #F5F9Fb;
border-right: 1px solid #e1f0f7;
}

.sidebar .nav-link-no-pulsable {
  background:transparent;
  font-size: 1.2em;
  font-weight: normal;
  padding: 10px 15px;
}

/* .sidebar .nav-content .pl-15 {
  padding-left: 15px;
} */

.element-body .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,.element-body .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{  
background-color: #2a95c3 !important;
}

/****************************************************************************************/
/*************************** Fin personalizacion colores  ******************************/
/***************************************************************************************/



.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-right: 10px;
  padding-left: 0px;
}

.colorLogin{  
  background-color:  #252f62 !important;
}

.logo span {  
font-size: 14px;
font-weight: 100;
color: #000;
font-family: "Nunito", sans-serif;
}
.logo span b{
  font-size: 19px;
  font-weight: bold;
  color:  #2a95c3 !important;
}
.logo span.img{
  background: #fff;
  background-image: url(/assets/img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 122px;
  height: 40px;
  margin-left: 10px;
}
span.profilelogo{
  background: #fff;
  background-image: url(/assets/img/profile.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}
.profilelogo.big{
  width: 150px;
  height: 150px;
}



/**** Tabla ******/
.tablaPersonal{
display: table;
width: 100%;  
background: #fff;
}
table {
  width: 100%;  
  margin-top: 15px;
}

.mat-form-field-infix {
  min-width: 60px !important;
  width: auto !important;
}
.tablaPersonal .mat-form-field {
width: 85% !important;
}

.tablaPersonal td.button div{
  display: flex;
  justify-content: flex-end;
}


.mat-paginator-container {
  justify-content: flex-start !important;
}


.mat-form-field {
  font-size: 14px;
  width: 50%;
}
.mat-column-position {
  max-width: 100px;
}
.mat-column-name {
  max-width: 100px;
}
.mat-sort-header-content {
  text-align: left !important;
  display: block !important;
  color: #444;
    font-weight: bolder;
}






.element-body .mat-checkbox-disabled .mat-checkbox-label,
.element-body .mat-input-element:disabled,.element-body .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after {
color: #000000 ;
}
tr.example-detail-row {
  height: 0 !important;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke !important;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef !important;
}

.example-element-row td {
  border-bottom-width: 0 !important;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}




.mat-expansion-panel:hover{
  background-color: #ccc;
}
.mat-expansion-panel-header{
  background-color: #e9e9e9;
}
.mat-expansion-panel-header.mat-expanded{
  height: 48px
}
.mat-accordion{
  margin-bottom: 10px;
}
.mat-expansion-panel-content {
  overflow: auto !important;
}
.mat-expansion-panel-body {
  padding: 16px 24px !important;
}



.personalMenu .toc{
  display: none;
}
.personalMenu .nav-item label{
  width: 100%;
}

.personalMenu li[data-level="2"]>input[type="checkbox"]:checked+label>a ,
.personalMenu li[data-level="1"]>input[type="checkbox"]:checked+label>a {
  color: #2a95c3 !important;
}

.personalMenu input[type="checkbox"]:checked+label>a {
  background-color: #eee;
}
.personalMenu input[type="checkbox"]+label>a {
  width: 100%;
  display: inline-block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.2em;
}

.personalMenu a {
  text-decoration: none;
}


.btn-aceptar{
  color: #ffffff !important;
  background-color: #2a95c3 !important;
  border-color: #2a95c3 !important;
  float: right !important;

}
.btn-cancelar{
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    float: left !important;

}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color:  #000 !important;
}


.cardCustos{
  padding: 10px;
    margin-bottom: 10px;
}

.cardCustos mat-checkbox{
  margin-left: 25px;
}
.cardCustos mat-checkbox .mat-checkbox-label{
  margin: 4px 0 0 10px;
}


.pagetitle{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}


.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #2a95c3 !important;  
  font-size: 24px;
}


ngx-numeric-range-form-field-control{
  margin-top: -10px;
}

@media (min-width: 1200px) {
	#main, #footer{
	  margin-left: 240px ;
	} 
}
.custom-modalbox.big{
  height: 90%;
}
.custom-modalbox main{
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding: 0px !important;
   
}



.sidebar{
  width: 240px !important;
}


.cardCustos .mat-form-field-infix{
  border-top:none
}

.cardCustos .mat-form-field{  
     width: 100% !important;
}


.cardCustos .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

.cardCustos .mat-input-element {
  caret-color: #dee2e6 !important;
}

.obligatorio{color:red}

.mat-dialog-container{height: auto !important;}



@media (max-width: 900px) {
  .custom-modalbox{
    width: 95% !important;
  }
  .custom-modalbox-big{
    width: 85% !important;
    max-width: 85% !important;
  }
}


.cardCustos ul{
  display: flex;
  flex-direction: column  !important;
}
.cardCustos ul li{
  width: 100% !important;
}

.Accumulations button, .Comparatives button, .breakdowns button , .Other button{
  position: absolute;
  right: 0;
  top: 11px;
}


.full-screem{
  width: 100%;
}



.card {
  margin-top: 10px;
  margin-bottom: 0px;
}


.apexcharts-canvas{

  width: 100%  !important;
}
.apexcharts-canvas svg{

  width: 100%  !important;
}


.mat-dialog-actions{
  justify-content: space-between;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 24px;
  /*padding-top: 8px;*/
}



td.mat-cell{
  padding-right: 5px !important;
}


.btnRefesh{
  border-radius: 7px;
  padding: 6px;
  height: 40px;
  border: 1px solid;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 165px !important;
  font-size: 11px;
  padding: 9px 0;
}

.mat-form-field-type-numeric-range-form-control .mat-form-field-infix {
  width: 100px !important;
}
.mat-form-field-infix {
  min-width: 70px !important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2a95c3 !important;
}
.mat-paginator{
  float: right !important;
}
.mat-paginator-page-size{
 
    display: flex;
    align-items: baseline;
    margin-right: 8px;
    flex-direction: row-reverse;
}

.mat-paginator-page-size-label{
  width: 53px;
}

.icoDownload{
  font-size: 30px;
  margin: 14px !important;
}

.numeric-range-field  .mat-form-field-flex{
  padding-top: 0 !important;
}


.cdk-overlay-pane {
  max-width: 98% !important;
  max-height: 90% !important;
}

.filterSelect{
  width: 97% !important; 
  padding-left: 8px;
}

.mat-button {
  padding: 0 8px !important;
}

.botonesCabecera{
  flex-direction: row;
  display: flex;
}

.botonMarcado{  
  color:#ffffff !important;
  background-color:#2a95c3 !important;
}

.filterCustos{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filterCard{
  width: 160px;
  margin-right: 20px;
}

.filterCard mat-form-field{
  width: 100% !important;
}

.selectButtom_navigation{
  display: flex;
  justify-content: center;
  margin: 0 8px;
  transition: height .3s cubic-bezier(.2,0,0,1),opacity .2s cubic-bezier(.2,0,0,1);
  border-radius: 40px 40px;
  background: #ccc;
  opacity: 1;
  height: 40px;  
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0)
}

.selectButtom {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
}

.selectButtom button {
   
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    border: none;
   /* border-radius: 40px;*/
    background: transparent;
    height: 40px;
    width: 101px;

}

.selectButtom button i{
margin-right: 5px;
}


.notifier__container {
  z-index: 9999;
}
.notifier__container * {
  box-sizing: border-box;
}
.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.notifier__notification {
  display: flex;
  align-items: center;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  backface-visibility: hidden;
}

.notifier__notification--material {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
  padding-top: 11px;
  padding-right: 26px;
  padding-bottom: 10px;
  padding-left: 26px;
}
.notifier__notification--material .notifier__notification-message {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 32px;
  font-size: 15px;
}
.notifier__notification--material .notifier__notification-button {
  display: inline-block;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  margin-right: -10px;
  margin-left: 10px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.notifier__notification--material .notifier__notification-button:hover, .notifier__notification--material .notifier__notification-button:focus {
  opacity: 1;
}
.notifier__notification--material .notifier__notification-button:active {
  transform: scale(0.82);
  opacity: 1;
}

.notifier__notification--default {
  background-color: #444;
  color: #fff;
}
.notifier__notification--default .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--error {
  background-color: #d9534f;
  color: #fff;
}
.notifier__notification--error .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--info {
  background-color: #5bc0de;
  color: #fff;
}
.notifier__notification--info .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--success {
  background-color: #5cb85c;
  color: #fff;
}
.notifier__notification--success .notifier__notification-button-icon {
  fill: #fff;
}

.notifier__notification--warning {
  background-color: #f0ad4e;
  color: #fff;
}
.notifier__notification--warning .notifier__notification-button-icon {
  fill: #fff;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2a95c3;
}

/* Status column */
.DEFAULT div{
  color:#0a0a0a;
  border: 1px solid #0a0a0a;
  border-radius: 4px;
  text-align: center;
  padding: 2px 16px;
  background: #ffffff;
}

.QUARANTINE div{
  color:#f10000;
  border: 1px solid #f10000;
  background: #ffffff;
}

.ACTIVE div{ 
  color: #00c500;
  border: 1px solid #00c500;
  background: #ffffff;
}

.INACTIVE div{ color:#ffb600;
  border: 1px solid #ffb600;
  background: #ffffff;
}

.PREACTIVE div{ color:#0077ff;
  border: 1px solid #0077ff;
  background: #ffffff;
}
